<template>
  <div class="container__img__aboutUs__containers__box4">
    <div class="container-fluid size d-flex mobile-aboutus">
      <div
        class="col-md-12 col-lg-12 col-xl-12 container__img__aboutUs__containers__box2 pl-5 pb-0 pt-5"
      >
        <h1 class="styles__title text-start m-auto text-center pt-5">
          TÉRMINOS Y CONDICIONES DE MAQUÍLLATE.COM
        </h1>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES
        </h2>

        <p class="styles__p m-auto text-center">
          Al acceder y utilizar la plataforma de maquillaje de cursos online, el
          usuario acepta los términos y condiciones de uso que se establecen a
          continuación. Si el usuario no está de acuerdo con los términos y
          condiciones, no debe utilizar la plataforma.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          PROPIEDAD INTELECTUAL
        </h2>
        <p class="styles__p m-auto text-center">
          Todo el contenido de la plataforma, incluyendo pero no limitado a
          textos, imágenes, videos, diseños gráficos, programas de software,
          bases de datos, y cualquier otra información disponible en la
          plataforma, es propiedad de la empresa y/o de sus respectivos dueños y
          se encuentra protegido por las leyes de propiedad intelectual.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          USO DE LA PLATAFORMA
        </h2>

        <p class="styles__p m-auto text-center">
          El usuario se compromete a utilizar la plataforma de maquillaje de
          cursos online de manera legal, ética y responsable. El usuario no debe
          utilizar la plataforma para:
        </p>

        <p class="styles__p m-auto text-center p-2">
          Subir, transmitir o compartir información o material que infrinja los
          derechos de autor, marca registrada, patente, secretos comerciales u
          otros derechos de propiedad intelectual de terceros.
        </p>
        <p class="styles__p m-auto text-center p-2">
          Publicar información falsa, difamatoria, obscena, ofensiva o ilegal.
        </p>
        <p class="styles__p m-auto text-center p-2">
          Utilizar la plataforma de forma fraudulenta o engañosa.
        </p>
        <p class="styles__p m-auto text-center p-2">
          Acceder a cuentas o información de otros usuarios sin su autorización.
        </p>
        <p class="styles__p m-auto text-center p-2">
          Intentar obtener acceso no autorizado a los sistemas o redes de la
          plataforma.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          REGISTRO DE USUARIO
        </h2>
        <p class="styles__p m-auto text-center">
          Para acceder a los cursos de la plataforma de maquillaje de cursos
          online, el usuario deberá registrarse en la plataforma proporcionando
          información precisa y completa. El usuario será responsable de
          mantener la confidencialidad de su nombre de usuario y contraseña, y
          será responsable de todas las actividades que se realicen bajo su
          cuenta.
        </p>
        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          PAGO Y CANCELACIONES
        </h2>
        <p class="styles__p m-auto text-center">
          El usuario deberá pagar por los cursos pagos que desee tomar a través
          de la plataforma. Los precios y los términos de pago estarán
          disponibles en la plataforma. La empresa se reserva el derecho de
          cancelar los cursos y reembolsar al usuario en caso de cualquier
          situación. El usuario podrá cancelar su pago en un curso hasta 7 días
          si no esta satisfecho y se le reembolsará el importe pagado.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          MODIFICACIONES EN LA PLATAFORMA Y LOS CURSOS
        </h2>
        <p class="styles__p m-auto text-center">
          La empresa se reserva el derecho de modificar la plataforma y los
          cursos en cualquier momento, incluyendo el contenido de los cursos, el
          precio y la disponibilidad de los mismos.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          LEY APLICABLE Y JURISDICCIÓN
        </h2>
        <p class="styles__p m-auto text-center">
          Estos términos y condiciones se regirán e interpretarán de acuerdo con
          las leyes del país en el que se encuentra la empresa. Cualquier
          disputa que surja en relación con estos términos y condiciones será
          resuelta por los tribunales competentes del país en el que se
          encuentra la empresa.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES
        </h2>
        <p class="styles__p m-auto text-center">
          La empresa se reserva el derecho de modificar estos términos y
          condiciones en cualquier momento que vea necesario.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FrequentQuestions',
};
</script>

<style>
.container__img__aboutUs__containers__box4 p {
  max-width: 900px;
  color: black;
}
</style>
